import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../../layouts";
import Article from "../../components/Article";
import Contact from "../../components/Contact";
import Headline from "../../components/Article/Headline";
import Seo from "../../components/Seo";
import ContactIcon from '../../../static/illustrations/contact_ar.svg'

const ContactPage = props => {
  const {
    data: {
      site: {
        siteMetadata: { facebook }
      }
    },
    language
  } = props;

  return (
    <React.Fragment>
      

      <ThemeContext.Consumer>
        {theme => (
          <div className="addMargin">
            <Contact ContactIcon= {ContactIcon} className="contact" theme={theme} />
            </div>
        )}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} language={language}/>
    <style jsx>{`
     .addMargin{ 
       margin-top: 100px;

    }
    
    `}</style>
    </React.Fragment>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query ContactQueryAr {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
